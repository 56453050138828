import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { OptionStore } from './option.store';

export const GroupStore = types
  .model('GroupStore', {
    uuid: types.identifier,
    options: types.array(OptionStore),
    name: types.string,
    level: types.maybeNull(types.number),
    selectedValue: types.maybe(types.string),

    /**
     * Will be hidden form URL as well as UI. But still available in request body
     */
    hidden: false,

    /**
     * Required. Need this key to put into URL
     */
    queryKey: types.string,

    structuralSeoId: types.maybeNull(types.string),

    requireLoggedIn: types.optional(types.boolean, false),
    order: types.optional(types.number, 0),
  })
  .actions((self) => {
    return {
      setSelectedValue(value: string) {
        self.selectedValue = value;
      },
    };
  })
  .views((self) => {
    return {
      get selectedOption() {
        return self.options.find((opt) => opt.value === self.selectedValue);
      },
      get visibleOptions() {
        return self.options.filter((opt) => !opt.hidden);
      },
    };
  });

export interface GroupStoreInstance extends Instance<typeof GroupStore> {}

export interface GroupStoreSnapshotIn extends SnapshotIn<typeof GroupStore> {}

export interface GroupStoreSnapshotOut extends SnapshotOut<typeof GroupStore> {}
