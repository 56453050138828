export const StructuralSEOSupportId = {
  WRITING_QUESTION_TYPE: 'WRITING_QUESTION_TYPE',
  TOPIC: 'TOPIC',
  LEVEL: 'LEVEL',
  TEST_QUESTION_TYPE: 'TEST_QUESTION_TYPE',
  VOCABULARY_TYPE: 'VOCABULARY_TYPE',
  SKILL: 'SKILL',
  YEAR: 'YEAR',
} as const;

export type StructuralSEOSupportId =
  typeof StructuralSEOSupportId[keyof typeof StructuralSEOSupportId];

export interface UrlTokenItem {
  pattern: RegExp;
  variables: string[];
}

export interface StructuralSEOItem {
  id: StructuralSEOSupportId;
  tokens: string[];
  urlTokens: UrlTokenItem[];
  getUrl: (value: any) => string;
  getTitle: (value: any) => string;
  getDescription: (value: any) => string;
  toVariables: (value: any) => any;
  toTokens: (value: any) => any;
}
