import { StructuralSEOItem, StructuralSEOSupportId } from '../seo-filter.types';
import {
  formatTextSEOStructural,
  seoFilterSlugifyUrl,
  topicStructuralSEOSupports,
} from './_CommonPageSEOStructure';

export const getPracticeTestQuestionTypeLabel = (value) => {
  const allOptions = [];
  return allOptions.find((option) => option.value === value)?.label || value;
};

const formatQuestionTypeTitleAndDescription = (value) =>
  formatTextSEOStructural({
    prefixText: 'dạng',
    formatFn: getPracticeTestQuestionTypeLabel,
    value: value?.type,
  });

const getQuestionTypeFilterUrl = ({ questionType }) => {
  const url = questionType;
  return seoFilterSlugifyUrl(url);
};

export const questionTypeStructuralSEOSupports: StructuralSEOItem[] = [
  {
    id: StructuralSEOSupportId.TEST_QUESTION_TYPE,
    tokens: ['{{dang}}'],
    urlTokens: [
      {
        pattern: /-dang-(.*)/,
        variables: ['type'],
      },
    ],
    getUrl: (value) => `-dang-${getQuestionTypeFilterUrl({ questionType: value.type })}`,
    getTitle: formatQuestionTypeTitleAndDescription,
    getDescription: formatQuestionTypeTitleAndDescription,
    toVariables: (value) => ({ type: value }),
    toTokens: (value) => ({ dang: value }),
  },
];

export const ieltsListeningPracticeStructuralSEOSupports: StructuralSEOItem[] = [
  ...questionTypeStructuralSEOSupports,
  ...topicStructuralSEOSupports,
];

export const ieltsReadingPracticeStructuralSEOSupports: StructuralSEOItem[] = [
  ...questionTypeStructuralSEOSupports,
  ...topicStructuralSEOSupports,
];
