import { StructuralSEOItem } from '../seo-filter.types';
import {
  formatTextSEOStructural,
  topicStructuralSEOSupports,
  yearStructuralSEOSupports,
} from './_CommonPageSEOStructure';

const getSpeakingSampleLabel = (value) => {
  const allOptions = [
    {
      label: 'Work and Study',
      value: 'Work & Study',
    },
  ];
  return allOptions.find((option) => option.value === value)?.label || value;
};

const formatSpeakingTopicTitleAndDescription = (value) =>
  formatTextSEOStructural({
    prefixText: 'topic',
    formatFn: getSpeakingSampleLabel,
    value: value?.topic,
  });

const speakingSampleTopic: StructuralSEOItem = {
  ...topicStructuralSEOSupports[0],
  getTitle: formatSpeakingTopicTitleAndDescription,
  getDescription: formatSpeakingTopicTitleAndDescription,
};

export const speakingSampleStructuralSEOSupports: StructuralSEOItem[] = [
  speakingSampleTopic,
  ...yearStructuralSEOSupports,
];
