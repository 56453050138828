import { camelCase, flatten, isNil } from 'lodash';
import { EslasticResponse, TransformedElasticResponse } from '@doltech/types';

/** {
 *  "meta": {
 *    "alerts": [],
 *    "warnings": [],
 *    "page": {
 *      "current": 1,
 *      "total_pages": 379,
 *      "total_results": 379,
 *      "size": 1
 *    },
 *    "engine": {
 *      "name": "dol-search-engine",
 *      "type": "default"
 *    },
 *    "request_id": "xi-u7-PrSsWRL7nGXJ8eQw"
 *  },
 *  "results": [
 *    {
 *      "image_url": {
 *        "raw": "https://gqefcpylonobj.vcdn.cloud/PUBLIC/MEDIA/d74f5338-815f-43d6-aca9-db6d58d16375.jpg"
 *      },
 *      "template_type_id": {
 *        "raw": "DO_DICTATION"
 *      },
 *      "description": {
 *        "raw": "Cambridge IELTS 15 is the latest IELTS exam preparation. DOL will help you to answer ..."
 *      },
 *      "title": {
 *        "raw": "How to spot"
 *      },
 *      "url": {
 *        "raw": "/chep-chinh-ta/how-to-spot7"
 *      },
 *      "takes": {
 *        "raw": "20"
 *      },
 *      "_meta": {
 *        "engine": "dol-search-engine",
 *        "score": 1.0,
 *        "id": "606bb324bd376a22a768f6eb"
 *      },
 *      "id": {
 *        "raw": "606bb324bd376a22a768f6eb"
 *      }
 *    }
 *  ],
 *  "facets": {
 *    "topic": [
 *      {
 *        "type": "value",
 *        "data": [
 *          {
 *            "value": "Economics & Business",
 *            "count": 1
 *          }
 *        ]
 *      }
 *    ]
 *  }
 *}
 */
export function transformElasticResponse(response: EslasticResponse): TransformedElasticResponse {
  const { meta, results, facets } = response;

  const transformed = {
    page: {},
    results: [],
    facets: {},
  };

  transformed.page = Object.entries(meta.page).reduce(
    (page, [k, v]) => ({ ...page, [camelCase(k)]: isNil(v) ? null : v }),
    {}
  );

  transformed.results = results.map((result) =>
    Object.entries(result).reduce(
      (r, [k, v]) => ({
        ...r,
        [camelCase(k)]: isNil(v.raw) ? null : v.raw,
        snippet: { ...r.snippet, [camelCase(k)]: isNil(v.snippet) ? null : v.snippet },
      }),
      {
        snippet: {},
      }
    )
  );

  transformed.facets = Object.entries(facets || {}).reduce(
    (result, [k, facet]) => ({
      ...result,
      [camelCase(k)]: flatten(facet.map((f) => f.data.map((datum) => datum.value))),
    }),
    {}
  );

  return transformed;
}
