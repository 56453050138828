import { slugify } from '@doltech/utils/lib/text';
import { Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';

export const OptionStore = types
  .model('OptionStore', {
    uuid: types.identifier,
    label: types.string,
    value: types.maybe(types.string),
    hidden: false,
    /** If this turn true, when question group has no selectedValue, this one will be */
    fallback: false,
  })
  .actions((self) => {
    return {};
  })
  .views((self) => {
    return {
      valueToUrl() {
        return slugify({
          text: self.value,
          trimMinusEndEnabled: true,
          trimMinusStartEnabled: false,
        });
      },
    };
  });

export interface OptionStoreInstance extends Instance<typeof OptionStore> {}
export interface OptionStoreSnapshotIn extends SnapshotIn<typeof OptionStore> {}
export interface OptionStoreSnapshotOut extends SnapshotOut<typeof OptionStore> {}
