import { ElasticRequestBody } from '@doltech/types';
import { PageTypeEnum } from '@doltech/utils/lib/constants';
import { isJson, toStringQuery } from '@doltech/utils/lib/object';
import { isNotEmpty } from '@doltech/utils/lib/text';
import { flatten, isNil, merge, pick, sortBy, sum, values } from 'lodash';
import { getSnapshot, Instance, SnapshotIn, SnapshotOut, types } from 'mobx-state-tree';
import { nanoid } from 'nanoid';

import { GroupStore, GroupStoreSnapshotIn } from './group.store';
import { OptionStore } from './option.store';
import {
  buildStructuralSeoFromString,
  extractSEOStructuralFromUrl,
  getStructuralSEOSupportsByPageType,
  stripFilterAndSearchInUrl,
} from './seo-filter.utils';

const DEFAULT_PAGE_SIZE = 10;

const DEFAULT_UPDATE_VALUE = {
  page: 1,
  size: 9,
  search: '',
};

const visibleGroupFilter = (gr) => !gr.hidden;

/**
 * NOTE: `sort` is one of `groups`. So, it will not be here
 */
export interface FilterUpdateDTO {
  groups?: GroupStoreSnapshotIn[];

  /**
   * queryKey from url, describes groups
   */
  [queryKey: string]: any;

  page?: number;
  size?: number;
  search?: string | undefined;
}

export const FilterStore = types
  .model('FilterStore', {
    basePath: types.maybeNull(types.string),
    uuid: types.string,
    groups: types.array(GroupStore),

    initialized: false,
    /** Turn `true` when first change is made to this store */
    changed: false,

    page: types.optional(types.number, 1),
    size: types.optional(types.number, DEFAULT_PAGE_SIZE),
    search: '',

    pageType: types.enumeration<any>(values(PageTypeEnum)),

    filterStyle: types.maybe(types.enumeration('FilterStyle', ['searchFirst', 'filterOnly'])),

    defaultValue: types.maybeNull(types.string),
    syncUrl: types.optional(types.boolean, false),
  })
  .volatile(() => ({
    structuralSeos: null,
    sortMenuVisible: false,
  }))
  .actions((self) => {
    const update = (data: FilterUpdateDTO, updateChanged = true) => {
      if (!data) return;

      if (updateChanged && !self.changed) {
        self.changed = true;
      }

      const { page, size, search, ...queryKeys } = data;

      const merged = merge({}, DEFAULT_UPDATE_VALUE, pick(self, ['page', 'size', 'search']), {
        page,
        size,
        search,
      });

      /** Query keys to groups */

      Object.entries(queryKeys).forEach(([k, v]) => {
        const group = self.groups.find((gr) => gr.queryKey === k);
        if (group) {
          group.setSelectedValue(v);
        }
      });

      self.search = merged.search;
      self.page = +merged.page;
      self.size = +merged.size;
    };

    const init = (input: FilterUpdateDTO, forced = false) => {
      if (self.initialized && !forced) return;

      self.groups.replace(
        input.groups.map((gr) =>
          GroupStore.create({
            ...gr,
            uuid: gr.uuid || nanoid(),
            options: gr.options.map((opt) =>
              OptionStore.create({ ...opt, uuid: opt.uuid || nanoid() })
            ),
          })
        )
      );

      update(input, false);

      self.initialized = true;
    };

    const upsertGroup = (input: GroupStoreSnapshotIn) => {
      const foundIndex = self.groups
        .filter(visibleGroupFilter)
        .findIndex((gr) => gr.queryKey === input.queryKey);

      /**
       *  (1) replace options
       *  (2) set selected value again
       *  (3) add new option with selected value
       *  (4) remove group
       *
       *                            |   options.length > 0  | options.length = 0  |
       *    ----------------------------------------------------------------------
       *      selected value = yes | 1, 2                   |  1, 3, 2            |
       *      selected value = no  | 1                      |  4                  |
       */

      if (foundIndex > -1) {
        const targetGroup = self.groups[foundIndex];
        const { selectedValue } = self.groups[foundIndex];

        targetGroup.setSelectedValue(undefined);

        if (input.options.length) {
          targetGroup.options.replace(input.options.map((opt) => OptionStore.create(opt)));

          if (isNotEmpty(selectedValue)) {
            targetGroup.setSelectedValue(selectedValue);
          }
        } else if (isNotEmpty(selectedValue)) {
          targetGroup.options.clear();
          targetGroup.options.push({
            uuid: nanoid(),
            label: selectedValue,
            value: selectedValue,
          });
          targetGroup.setSelectedValue(selectedValue);
        } else {
          self.groups.splice(foundIndex, 1);
        }
      } else if (input.options.length) {
        self.groups.unshift(GroupStore.create(input));
      }
    };

    const updateFilterBasedOnStructuralSeo = () => {
      const structuralSeos = extractSEOStructuralFromUrl({
        url: window.location.pathname,
        pageType: self.pageType,
      });
      self.structuralSeos = structuralSeos;
      self.structuralSeos.forEach((structuredSeoInfo) => {
        const foundGroup = self.groups.find((group) => {
          return group.structuralSeoId === structuredSeoInfo.id;
        });
        if (foundGroup) {
          const foundOptionValue = foundGroup.options.find(
            (option) =>
              structuredSeoInfo.getUrl(structuredSeoInfo.toVariables(option.value)) ===
              structuredSeoInfo.getUrl(structuredSeoInfo.variables)
          );
          if (foundOptionValue) {
            foundGroup.setSelectedValue(foundOptionValue.value);
          }
        }
      });
    };

    const updateUrl = () => {
      const basePath =
        self.basePath ||
        stripFilterAndSearchInUrl({
          url: window.location.pathname,
        });
      const structuralSEOSupports = getStructuralSEOSupportsByPageType({ pageType: self.pageType });
      let newUrl = basePath;

      const groupsHaveFilter = self.groups.filter(
        (group) => Boolean(group.structuralSeoId) && Boolean(group.selectedValue)
      );
      groupsHaveFilter.forEach((group) => {
        const foundStructuralSeo = structuralSEOSupports.find(
          (item) => item.id === group.structuralSeoId
        );
        if (foundStructuralSeo) {
          const appendUrl = foundStructuralSeo.getUrl(
            foundStructuralSeo.toVariables(group.selectedValue)
          );
          newUrl = `${newUrl}${appendUrl}`;
        }
      });

      if (self.syncUrl) {
        window.history.replaceState(
          { ...window.history.state, as: newUrl, url: newUrl },
          '',
          newUrl
        );
      }
    };

    return {
      init,
      update,
      setSortMenuVisible(state) {
        self.sortMenuVisible = state;
      },
      setInitialized() {
        self.initialized = true;
      },
      updateUrl,
      updateFilterBasedOnStructuralSeo,
      /**
       * Sync between desktop store & mobile store
       * In this case, only `selectedValue` is needed
       */
      sync(data: FilterUpdateDTO) {
        init(data, true);
        self.changed = true;
      },
      clearFilter() {
        if (self.defaultValue) {
          const defaultGroups = JSON.parse(self.defaultValue)?.groups;
          self.groups.replace(
            self.groups.map((item) => {
              const found = defaultGroups.find((g) => g.name === item.name);
              item.selectedValue = found ? found.selectedValue : undefined;
              return item;
            })
          );
        } else {
          self.groups
            .filter(visibleGroupFilter)
            .forEach((group) => group.setSelectedValue(undefined));
        }
        update(DEFAULT_UPDATE_VALUE);
        updateUrl();
      },
      upsertGroup,
      setPage(newPage) {
        self.page = newPage;
      },
      batchUpsertGroups(groups) {
        groups.filter(Boolean).forEach((group) => {
          upsertGroup(group);
        });
      },
    };
  })
  .views((self) => {
    const getCurrentVariables = () => {
      const structuralSEOSupports = getStructuralSEOSupportsByPageType({ pageType: self.pageType });
      let variables = {};
      const groupsHaveFilter = self.groups.filter(
        (group) => Boolean(group.structuralSeoId) && Boolean(group.selectedValue)
      );
      groupsHaveFilter.forEach((group) => {
        const foundStructuralSeo = structuralSEOSupports.find(
          (item) => item.id === group.structuralSeoId
        );
        if (foundStructuralSeo) {
          variables = { ...variables, ...foundStructuralSeo.toVariables(group.selectedValue) };
        }
      });
      return variables;
    };

    const getElasticQueryView = () => {
      const snapshot = getSnapshot(self);
      return getElasticQuery(snapshot);
    };

    return {
      getSeoTitle(originalTitle) {
        return buildStructuralSeoFromString({
          input: originalTitle,
          variables: getCurrentVariables(),
          structuralType: 'title',
          pageType: self.pageType,
        });
      },
      getSeoDescription(originalDescription) {
        return buildStructuralSeoFromString({
          input: originalDescription,
          variables: getCurrentVariables(),
          structuralType: 'title',
          pageType: self.pageType,
        });
      },
      get visibleGroups() {
        return sortBy(self.groups.filter(visibleGroupFilter), 'order');
      },
      get visibleGroupWithoutSort() {
        return self.groups.filter(visibleGroupFilter).filter((group) => group.queryKey !== 'sort');
      },
      get selectedCount() {
        return countSelected(self);
      },
      /**
       * Return object with {page, size, search, sort, ...soOn } and so on (whatever groups are)
       */
      get query() {
        return parseQuery(getSnapshot(self));
      },
      /**
       * Like @query() but URL string
       */
      get pathQuery() {
        return toStringQuery(parseQuery(getSnapshot(self)));
      },
      get elasticQuery() {
        return getElasticQueryView();
      },
      get isSearchFirst() {
        return self?.search?.trim()?.length > 0;
      },
      get isFilterOnly() {
        const atleastOneFilter = self.groups.some(
          (group) => group.queryKey !== 'sort' && group.selectedValue && !group.hidden
        );
        return atleastOneFilter;
      },
      get hasStyle() {
        return !isNil(self.filterStyle);
      },
      get elasticQueryKey() {
        return JSON.stringify(getElasticQueryView());
      },
    };
  });

export interface FilterStoreInstance extends Instance<typeof FilterStore> {}

export interface FilterStoreSnapshotIn extends SnapshotIn<typeof FilterStore> {}

export interface FilterStoreSnapshotOut extends SnapshotOut<typeof FilterStore> {}

/**
 * get Elastic search query object
 */
export function getElasticQuery(snapshot: any, defaultFacets = {}): ElasticRequestBody {
  const { page, size, search, groups } = snapshot;

  const query = {
    query: search ?? '',
    filters: {
      all: [],
    },
    facets: {
      topic: [
        {
          type: 'value',
        },
      ],
      ...defaultFacets,
    },
    page: {
      current: page,
      size,
    },
    sort: [],
  };

  const sortGroup = groups.find((gr) => gr.queryKey === 'sort');
  if (sortGroup) {
    if (isNotEmpty(sortGroup.selectedValue)) {
      const multipleSorts = sortGroup.selectedValue.split('&');
      query.sort = [];
      for (const sortCriteria of multipleSorts) {
        const [criteria, direction] = sortCriteria.split(',');
        query.sort.push({
          [criteria]: direction,
        });
      }
    } else {
      delete query.sort;
    }
  } else {
    delete query.sort;
  }

  query.filters.all = groups
    .filter((gr) => gr.queryKey !== 'sort')
    .filter((gr) => !gr.sort)
    .filter((gr) => isNotEmpty(gr.selectedValue))
    .map((gr) => {
      const value = isJson(gr.selectedValue) ? JSON.parse(gr.selectedValue) : gr.selectedValue;
      const queryKeyName = gr.queryKey;

      return {
        [queryKeyName]: value,
      };
    });

  return query;
}

export function getElasticQuerySpecifyForDictation(snapshot: any): any {
  const { page, size, search, groups } = snapshot;

  const query = {
    query: search ?? '',
    filters: {
      all: [],
    },
    page: {
      current: page,
      size,
    },
    sort: [],
  };

  const sortGroup = groups.find((gr) => gr.queryKey === 'sort');
  if (sortGroup) {
    if (isNotEmpty(sortGroup.selectedValue)) {
      const multipleSorts = sortGroup.selectedValue.split('&');
      query.sort = [];
      for (const sortCriteria of multipleSorts) {
        const [criteria, direction] = sortCriteria.split(',');
        query.sort.push({
          [criteria]: direction,
        });
      }
    } else {
      delete query.sort;
    }
  } else {
    delete query.sort;
  }

  query.filters.all = groups
    .filter((gr) => gr.queryKey !== 'sort')
    .filter((gr) => !gr.sort)
    .filter((gr) => isNotEmpty(gr.selectedValue))

    .map((gr) => {
      const value = isJson(gr.selectedValue) ? JSON.parse(gr.selectedValue) : gr.selectedValue;
      const queryKeyName = gr.queryKey;

      return {
        [queryKeyName]: value,
      };
    });

  return query;
}

export const parseQuery = (snapshot: FilterStoreSnapshotIn) => {
  const { page, size, search, groups } = snapshot;

  const queryKeys = groups.filter(visibleGroupFilter).reduce((result, gr) => {
    const newResult = result;
    if (gr.selectedValue) {
      newResult[gr.queryKey] = gr.selectedValue;
    }
    return newResult;
  }, {});

  return { page, size, search, ...queryKeys };
};

/**
 * Count visible groups's selectedValue
 */
const countSelected = (self) => {
  const visibleGroups = self.groups.filter(visibleGroupFilter).filter((g) => g.queryKey !== 'sort');
  return sum(
    flatten(
      visibleGroups.map((group) =>
        isNotEmpty(group.selectedValue) && !group.selectedOption?.hidden ? 1 : 0
      )
    )
  );
};
