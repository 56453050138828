import { StructuralSEOItem, StructuralSEOSupportId } from '../seo-filter.types';
import {
  formatTextSEOStructural,
  seoFilterSlugifyUrl,
  topicStructuralSEOSupports,
} from './_CommonPageSEOStructure';

const getDictationLevelLabel = (value) => {
  const allOptions = [
    {
      label: 'Dễ',
      value: 'EASY',
    },
    {
      label: 'Trung bình',
      value: 'MEDIUM',
    },
    {
      label: 'Khó',
      value: 'HARD',
    },
  ];
  return allOptions.find((option) => option.value === value)?.label || value;
};

const getUrlByDictationLevel = (level) => {
  let url = level;
  switch (level) {
    case 'EASY': {
      url = 'de';
      break;
    }
    case 'MEDIUM': {
      url = 'trung-binh';
      break;
    }
    case 'HARD': {
      url = 'kho';
      break;
    }
    default:
  }
  return seoFilterSlugifyUrl(url);
};

const formatDictationLevelTitleAndDescription = (value) =>
  formatTextSEOStructural({
    prefixText: 'trình độ',
    formatFn: getDictationLevelLabel,
    value: value?.level,
  });

const dicationLevelStructuralSEOSupports = [
  {
    id: StructuralSEOSupportId.LEVEL,
    tokens: ['{{level}}'],
    urlTokens: [
      {
        pattern: /-level-(.*)/,
        variables: ['level'],
      },
    ],
    getUrl: (value) => `-level-${getUrlByDictationLevel(value.level)}`,
    getTitle: formatDictationLevelTitleAndDescription,
    getDescription: formatDictationLevelTitleAndDescription,
    toVariables: (value) => ({ level: value }),
    toTokens: (value) => ({ level: value }),
  },
];

export const dictationStructuralSEOSupports: StructuralSEOItem[] = [
  ...topicStructuralSEOSupports,
  ...dicationLevelStructuralSEOSupports,
];
