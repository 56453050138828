import { FilterStore } from './filter.store';

/**
 * Why this one exist along with `FilterStore`
 * - `FilterStore` is served for `FilterDesktop` which is interactions in it effect immediately
 * - While the filer in tablets, mobiles is placed inside a `Drawer`, need to confirm/cancel to do next action -> need to store
 *   actions in drawer in temporary store, if `Confirm` -> apply to `FilterStore`
 */
export const FilterMobileStore = FilterStore.named('FilterMobileStore')
  .props({
    /**
     * Indicate the `Drawer` open or not
     */
    open: false,
  })
  .actions((self) => {
    return {
      setOpen() {
        self.open = true;
      },
      setClose() {
        self.open = false;
      },
    };
  })
  .views((self) => {
    return {};
  });

