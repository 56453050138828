import { slugify } from '@doltech/utils/lib/text';
import { StructuralSEOItem, StructuralSEOSupportId } from '../seo-filter.types';

export const seoFilterSlugifyUrl = (text) =>
  slugify({
    text,
    separator: '-',
    trimMinusStartEnabled: false,
    trimMinusEndEnabled: true,
  });

export const yearStructuralSEOSupports: StructuralSEOItem[] = [
  {
    id: StructuralSEOSupportId.YEAR,
    tokens: ['{{year}}'],
    urlTokens: [
      {
        pattern: /-nam-(.*)/,
        variables: ['year'],
      },
    ],
    getUrl: (value) => `-nam-${seoFilterSlugifyUrl(value.year)}`,
    getTitle: (value) => (value?.year ? `năm ${value.year}` : ''),
    getDescription: (value) => (value?.year ? `năm ${value.year}` : ''),
    toVariables: (value) => ({ year: value }),
    toTokens: (value) => ({ year: value }),
  },
];

export const formatTextSEOStructural = ({ prefixText, formatFn, value }) => {
  return value ? `${prefixText ? `${prefixText} ` : ''}${formatFn ? formatFn(value) : value}` : '';
};

export const topicStructuralSEOSupports: StructuralSEOItem[] = [
  {
    id: StructuralSEOSupportId.TOPIC,
    tokens: ['{{topic}}'],
    urlTokens: [
      {
        pattern: /-topic-(.*)/,
        variables: ['topic'],
      },
    ],
    getUrl: (value) => `-topic-${seoFilterSlugifyUrl(value.topic)}`,
    getTitle: (value) => (value?.topic ? `Topic ${value.topic}` : ''),
    getDescription: (value) => (value?.topic ? `Topic ${value.topic}` : ''),
    toVariables: (value) => ({ topic: value }),
    toTokens: (value) => ({ topic: value }),
  },
];
