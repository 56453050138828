import { apis } from '@doltech/core/lib/api/api.config';
import { apiUrls } from '@doltech/core/lib/api/api.urls';
import { transformElasticResponse } from '@doltech/utils/lib/elastic';
import { genEmptyPagable } from '@doltech/utils/lib/object';
import { apiPublicUrls } from '@doltech/core/lib/api/api.public-urls';

export const updateExamQuestionsPrediction = async (params) => {
  const { data } = await apis.pageManagement.put(
    apiUrls.pageManagement.UPDATE_EXAM_QUESTIONS_PREDICTION,
    params
  );
  return data;
};

export const getRecommendedPracticeTests = async (params) => {
  const { data } = await apis.pageManagement.get(
    apiUrls.pageManagement.GET_RECOMMEND_PRACTICE_TESTS,
    params
  );
  return data;
};

export const getRecommendedDictations = async () => {
  const { data } = await apis.pageManagement.get(apiUrls.pageManagement.GET_RECOMMEND_DICTATIONS);
  return data;
};

export const getExamQuestionsPrediction = async (params) => {
  const res = await apis.publicPageManagement.get(
    apiPublicUrls.pageManagement.GET_EXAM_QUESTIONS_PREDICTION,
    params
  );

  return res.data as any;
};

export const searchTransformerFilterSamples = async (params) => {
  const res = await apis.publicSearchTransform.post(
    apiPublicUrls.searchTransform.FILTER_SAMPLE,
    params
  );
  const { results, page, facets } = transformElasticResponse(res.data as any);

  let data: any = {};
  if (results.length > 0) {
    data.content = results.map((result: any) => {
      if (result.topic) {
        result.topic = { name: result.topic };
      }
      if (result.imageUrl) {
        result.thumbnail = { alt: '', url: result.imageUrl };
      }
      return result;
    });
  } else {
    data = genEmptyPagable({ size: params.page.size });
  }

  return {
    data,
    page,
    facets,
  };
};

export const searchTransformerFilterIeltsBlog = async (params) => {
  const res = await apis.publicSearchTransform.post(
    apiPublicUrls.searchTransform.FILTER_IELTS_BLOGS,
    params
  );
  const { results, page, facets } = transformElasticResponse(res.data as any);

  let data: any = {};
  if (results.length > 0) {
    data.content = results;
  } else {
    data = genEmptyPagable({ size: params.page.size });
  }

  return {
    data,
    page,
    facets,
  };
};

export const searchTransformerFilterVocabs = async (params) => {
  const res = await apis.publicSearchTransform.post(
    apiPublicUrls.searchTransform.FILTER_VOCABS,
    params
  );
  const { results, page, facets } = transformElasticResponse(res.data as any);

  let data: any = {};
  if (results.length > 0) {
    data.content = results;
  } else {
    data = genEmptyPagable({ size: params.page.size });
  }

  return {
    data,
    page,
    facets,
  };
};

export const getMyTestIds = async (params) => {
  const { data } = await apis.onlineTest.get(apiUrls.onlineTest.GET_MY_TEST_IDS, params);
  return data;
};

export const getMyDictationIds = async (params) => {
  const { data } = await apis.onlineTest.get(apiUrls.onlineTest.GET_MY_DICTATION_IDS, params);
  return data;
};
