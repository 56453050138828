import { StructuralSEOItem, StructuralSEOSupportId } from '../seo-filter.types';
import {
  formatTextSEOStructural,
  seoFilterSlugifyUrl,
  topicStructuralSEOSupports,
  yearStructuralSEOSupports,
} from './_CommonPageSEOStructure';

const getWritingQuestionTypeLabel = (value) => {
  const allOptions = [
    {
      label: 'Agree or disagree',
      value: 'AGREE_AND_DISAGREE',
    },
    {
      label: 'Discuss both views',
      value: 'DISCUSS_BOTH_VIEWS',
    },
    {
      label: 'Two-part questions',
      value: 'TWO_PART_QUESTIONS',
    },
    {
      label: 'Problems-causes-solutions',
      value: 'PROBLEMS_CAUSES_SOLUTIONS',
    },
    {
      label: 'Advantages and Disadvantages',
      value: 'ADVANTAGES_AND_DISADVANTAGES',
    },
    {
      label: 'Bar Chart',
      value: 'BAR_CHART',
    },
    {
      label: 'Pie Chart',
      value: 'PIE_CHART',
    },
    {
      label: 'Table',
      value: 'TABLE',
    },
    {
      label: 'Line Graph',
      value: 'LINE',
    },
    {
      label: 'Mixed Charts',
      value: 'MIXED',
    },
    {
      label: 'Process',
      value: 'PROCESS',
    },
    {
      label: 'Map',
      value: 'MAP',
    },
    {
      label: 'Thank Letter',
      value: 'THANK_LETTER',
    },
    {
      label: 'Request Letter',
      value: 'REQUEST_LETTER',
    },
    {
      label: 'Complaint Letter',
      value: 'COMPLAINT_LETTER',
    },
    {
      label: 'Apology Letter',
      value: 'APOLOGY_LETTER',
    },
    {
      label: 'Advice Seeking Letter',
      value: 'ADVICE_SEEKING_LETTER',
    },
  ];
  return allOptions.find((option) => option.value === value)?.label || value;
};

const getWritingSampleFilterUrl = ({ writingQuestionType }) => {
  let url = writingQuestionType;
  switch (url) {
    case 'MIXED': {
      url = 'MIXED_CHARTS';
      break;
    }
    case 'LINE': {
      url = 'LINE_GRAPH';
      break;
    }
    default:
  }
  return seoFilterSlugifyUrl(url);
};

const formatWritingTitleAndDescription = (value) =>
  formatTextSEOStructural({
    prefixText: 'dạng',
    formatFn: getWritingQuestionTypeLabel,
    value: value?.writingQuestionType,
  });

export const writingSampleTask1StructuralSEOSupports: StructuralSEOItem[] = [
  {
    id: StructuralSEOSupportId.WRITING_QUESTION_TYPE,
    tokens: ['{{dang_de_bai}}'],
    urlTokens: [
      {
        pattern: /-dang-de-bai-(.*)/,
        variables: ['writingQuestionType'],
      },
    ],
    getUrl: (value) =>
      `-dang-de-bai-${getWritingSampleFilterUrl({
        writingQuestionType: value.writingQuestionType,
      })}`,
    getTitle: formatWritingTitleAndDescription,
    getDescription: formatWritingTitleAndDescription,
    toVariables: (value) => ({ writingQuestionType: value }),
    toTokens: (value) => ({ dang_de_bai: value }),
  },
  ...yearStructuralSEOSupports,
];

export const writingSampleTask2AcademicStructuralSEOSupports: StructuralSEOItem[] = [
  ...writingSampleTask1StructuralSEOSupports,
  ...topicStructuralSEOSupports,
];
