/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-loop-func */

import { PageTypeEnum } from '@doltech/utils/lib/constants';
import { Liquid } from 'liquidjs';
import { isNil } from 'lodash';
import { StructuralSEOItem } from './seo-filter.types';
import { bookReviewStructuralSEOSupports } from './supportedPage/BookReviewSEOStructure';
import { dictationStructuralSEOSupports } from './supportedPage/DictationSEOStructure';
import {
  ieltsListeningPracticeStructuralSEOSupports,
  ieltsReadingPracticeStructuralSEOSupports,
} from './supportedPage/IeltsPracticeSEOStructure';
import { speakingSampleStructuralSEOSupports } from './supportedPage/SpeakingSampleSEOStructure';
import { vocabularyStructuralSEOSupports } from './supportedPage/VocabularySEOStructure';
import {
  writingSampleTask1StructuralSEOSupports,
  writingSampleTask2AcademicStructuralSEOSupports,
} from './supportedPage/WritingSampleSEOStructure';

const liquidEngine = new Liquid({
  cache: true,
});

liquidEngine.registerFilter('prependIfNotEmpty', (initial, arg1) => {
  if (isNil(initial)) {
    return null;
  }
  return `${arg1}${initial}`;
});

liquidEngine.registerFilter('appendIfNotEmpty', (initial, arg1) => {
  if (isNil(initial)) {
    return null;
  }
  return `${initial}${arg1}`;
});

export const PageTypeSupportFilterAndSearch = [
  PageTypeEnum.LANDING_READING_PRACTICE_TEST,
  PageTypeEnum.LANDING_LISTENING_PRACTICE_TEST,
  PageTypeEnum.LANDING_DICTATION,
  PageTypeEnum.LANDING_BOOK_REVIEW,
  PageTypeEnum.LANDING_VOCAB_IELTS_BLOG,
  PageTypeEnum.LANDING_WRITING_SAMPLE_T1,
  PageTypeEnum.LANDING_WRITING_SAMPLE_T2A,
  PageTypeEnum.LANDING_WRITING_SAMPLE_T2G,
  PageTypeEnum.LANDING_SPEAKING_SAMPLE_P1,
  PageTypeEnum.LANDING_SPEAKING_SAMPLE_P2,
  PageTypeEnum.LANDING_SPEAKING_SAMPLE_P3,
];

export const isPageTypeSupportFilterAndSearch = (pageType) => {
  return PageTypeSupportFilterAndSearch.includes(pageType);
};

interface GetStructuralSEOSupportsByPageTypeParam {
  pageType: any;
}

// document ref: https://docs.google.com/spreadsheets/d/1WUzL4b2nEowmv_WMEuFUPfWyXT_XHR3aiTdeQjRW9CQ/edit#gid=634672849
export const getStructuralSEOSupportsByPageType = ({
  pageType,
}: GetStructuralSEOSupportsByPageTypeParam) => {
  switch (pageType) {
    case PageTypeEnum.LANDING_READING_PRACTICE_TEST: {
      return ieltsReadingPracticeStructuralSEOSupports;
    }
    case PageTypeEnum.LANDING_LISTENING_PRACTICE_TEST: {
      return ieltsListeningPracticeStructuralSEOSupports;
    }
    case PageTypeEnum.LANDING_DICTATION: {
      return dictationStructuralSEOSupports;
    }
    case PageTypeEnum.LANDING_BOOK_REVIEW: {
      return bookReviewStructuralSEOSupports;
    }
    case PageTypeEnum.LANDING_VOCAB_IELTS_BLOG: {
      return vocabularyStructuralSEOSupports;
    }

    case PageTypeEnum.LANDING_WRITING_SAMPLE_T1:
    case PageTypeEnum.LANDING_WRITING_SAMPLE_T2G: {
      // note: task 2 general rename to task 1 academic (from Kiet)
      return writingSampleTask1StructuralSEOSupports;
    }
    case PageTypeEnum.LANDING_WRITING_SAMPLE_T2A: {
      return writingSampleTask2AcademicStructuralSEOSupports;
    }
    case PageTypeEnum.LANDING_SPEAKING_SAMPLE_P1:
    case PageTypeEnum.LANDING_SPEAKING_SAMPLE_P2:
    case PageTypeEnum.LANDING_SPEAKING_SAMPLE_P3: {
      return speakingSampleStructuralSEOSupports;
    }
  }
  return [];
};

/**
 *
 * @example
 * input: '/full-test-speaking-qui-3-2021'
 * output: {
 *    tokens: [ '{{qui}}' ],
 *    urlTokens: [ [Object] ],
 *    getUrl: [λ: getUrl],
 *    getTitle: [λ: getTitle],
 *    getDescription: [λ: getDescription],
 *    variables: { quarter: '3', year: '2021' }
 * }
 */
interface ExtractSEOStructuralFromUrlParams {
  pageType: any;
  url: string;
}
interface ExtractedStructuralSEOItemFromUrl extends StructuralSEOItem {
  variables: any;
}
export const extractSEOStructuralFromUrl = ({
  pageType,
  url,
}: ExtractSEOStructuralFromUrlParams): ExtractedStructuralSEOItemFromUrl[] => {
  let result = [];
  let tempUrl = url;

  /**
   * First we search for all seo structure that have pattern match with url
   * for example we have url: "http://localhost:8000/ielts-writing-task-2-academic-sample1-dang-de-bai-two-part-questions-topic-lifestyle"
   * => we'll have 2 matches:
   *    - match 1: {{dang-de-bai-two-part-questions-topic-lifestyle}} (follow the pattern of /(.*)/)
   *    - match 2: dang-de-bai-two-part-questions-{{topic-lifestyle}}
   * based on the result, extract from the last matches(match 2 in this case) is the way to go
   * so we need to sort and then loop through the matches
   *
   *  */
  const structuralSEOSupports = getStructuralSEOSupportsByPageType({ pageType });

  const foundStructuralSeos = structuralSEOSupports
    .map((structuralSeo: any) => {
      structuralSeo.urlTokens.find((urlToken) => {
        const match: any = urlToken.pattern.exec(tempUrl);
        const isMatch = Boolean(match);
        if (isMatch) {
          structuralSeo.matchIndex = match.index;
        }
        return isMatch;
      });
      return structuralSeo;
    })
    .filter((item) => item.matchIndex != null);
  foundStructuralSeos.sort((a, b) => b.matchIndex - a.matchIndex);
  // now loop through the matches and extract variables
  result = foundStructuralSeos.map((structuralSeo: any) => {
    let variables = {};
    structuralSeo.urlTokens.forEach((urlToken) => {
      const match: any = urlToken.pattern.exec(tempUrl);
      const isMatch = Boolean(match);
      if (isMatch) {
        const matchVariables = structuralSeo.toVariables(match[1]);
        variables = {
          ...variables,
          ...matchVariables,
        };
      }
    });
    const stringToReplace = structuralSeo.getUrl(variables);
    tempUrl = tempUrl.replace(stringToReplace, '');
    structuralSeo.variables = variables;
    delete structuralSeo.matchIndex;
    return structuralSeo;
  });
  return result;
};

interface BuildStructuralSeoFromStringParams {
  input: string;
  variables: any;
  structuralType: 'url' | 'title' | 'description';
  pageType: any;
}

/**
 *
 * @examples 1
 * - input: {
 *      input: 'Bài viết với {{topic}}',
 *      variables: { topic: 'Environment' }
 *      structuralType: 'title'
 *   }
 * - output: 'Bài viết với Topic Environment'
 *
 * @examples 2
 * - input: {
 *      input: '/full-test-speaking{{qui}}',
 *      structuralType: 'url',
 *      variables: { quarter: 3, year: 2021 },
 *   }
 * - output: '/full-test-speaking-qui-3-2021'
 */
export const buildStructuralSeoFromString = (
  params: BuildStructuralSeoFromStringParams
): string => {
  const { pageType, input, variables, structuralType = 'url' } = params;
  let currStr = input;
  const structuralSEOSupports = getStructuralSEOSupportsByPageType({ pageType });

  if (structuralType === 'url') {
    const foundToken = structuralSEOSupports.find((structuralSeo) => {
      return structuralSeo.tokens.some((token) => currStr?.includes(token));
    });
    if (foundToken) {
      foundToken.tokens.forEach((token) => {
        currStr = currStr.replace(token, foundToken.getUrl(variables));
      });
    }
  } else {
    try {
      // title, description
      let collectVariables = {};
      Object.keys(variables).forEach((k) => {
        const foundToken = structuralSEOSupports.find((structuralSeo) =>
          structuralSeo.urlTokens.some((urlToken) => urlToken?.variables?.includes(k))
        );
        let tokenValue = {};
        switch (structuralType) {
          case 'title': {
            tokenValue = foundToken.toTokens(foundToken.getTitle(variables));
            break;
          }
          case 'description': {
            tokenValue = foundToken.toTokens(foundToken.getDescription(variables));
            break;
          }
          default:
        }
        if (foundToken) {
          collectVariables = {
            ...collectVariables,
            ...tokenValue,
          };
        }
      });
      currStr = liquidEngine.parseAndRenderSync(input, collectVariables);
      // eslint-disable-next-line no-empty
    } catch (ex) {
      console.error('Error buildStructuralSeoFromString: ', ex.message, ex.stack);
    }
  }

  return currStr?.trim();
};

/**
 *
 * @example 1
 * - input: '/full-test-speaking-qui-3-2000'
 * - output: '/full-test-speaking'
 */
interface StripFilterAndSearchInUrlParams {
  url: string;
}
export const stripFilterAndSearchInUrl = ({ url }: StripFilterAndSearchInUrlParams): string => {
  let result = url;
  PageTypeSupportFilterAndSearch.forEach((pageType) => {
    const structuralSEOSupports = getStructuralSEOSupportsByPageType({ pageType });
    if (structuralSEOSupports?.length > 0) {
      structuralSEOSupports.forEach((structuralSeo) => {
        structuralSeo.urlTokens.forEach((urlToken) => {
          result = result.replace(urlToken.pattern, '');
        });
      });
    }
  });
  return result;
};
