import { StructuralSEOItem, StructuralSEOSupportId } from '../seo-filter.types';
import { formatTextSEOStructural, seoFilterSlugifyUrl } from './_CommonPageSEOStructure';

const getIeltsSKillLabel = (value) => {
  const allOptions = [
    {
      label: 'Writing',
      value: 'WRITING',
    },
    {
      label: 'Speaking',
      value: 'SPEAKING',
    },
    {
      label: 'Reading',
      value: 'READING',
    },
    {
      label: 'Listening',
      value: 'LISTENING',
    },
    {
      label: 'Vocabulary',
      value: 'VOCAB',
    },
    {
      label: 'Grammar',
      value: 'GRAMMAR',
    },
  ];
  return allOptions.find((option) => option.value === value)?.label || value;
};

const formatBookReviewSkillTitleAndDescription = (value) =>
  formatTextSEOStructural({
    prefixText: '',
    formatFn: getIeltsSKillLabel,
    value: value?.skill,
  });

const getBookReviewLevelLabel = (value) => {
  const allOptions = [
    {
      label: 'Basic',
      value: 'BASIC',
    },
    {
      label: 'Advanced',
      value: 'ADVANCED',
    },
  ];
  return allOptions.find((option) => option.value === value)?.label || value;
};

const formatBookReviewLevelTitleAndDescription = (value) =>
  formatTextSEOStructural({
    prefixText: '',
    formatFn: getBookReviewLevelLabel,
    value: value?.level,
  });

const bookReviewLevelStructuralSEOSupports: StructuralSEOItem[] = [
  {
    id: StructuralSEOSupportId.LEVEL,
    tokens: ['{{level}}'],
    urlTokens: [
      {
        pattern: /-level-(.*)/,
        variables: ['level'],
      },
    ],
    getUrl: (value) => `-level-${seoFilterSlugifyUrl(value.level)}`,
    getTitle: formatBookReviewLevelTitleAndDescription,
    getDescription: formatBookReviewLevelTitleAndDescription,
    toVariables: (value) => ({ level: value }),
    toTokens: (value) => ({ level: value }),
  },
];

const bookReviewSkillStructuralSEOSupports: StructuralSEOItem[] = [
  {
    id: StructuralSEOSupportId.SKILL,
    tokens: ['{{skill}}'],
    urlTokens: [
      {
        pattern: /-ky-nang-(.*)/,
        variables: ['skill'],
      },
    ],
    getUrl: (value) => `-ky-nang-${seoFilterSlugifyUrl(value.skill)}`,
    getTitle: formatBookReviewSkillTitleAndDescription,
    getDescription: formatBookReviewSkillTitleAndDescription,
    toVariables: (value) => ({ skill: value }),
    toTokens: (value) => ({ skill: value }),
  },
];

export const bookReviewStructuralSEOSupports: StructuralSEOItem[] = [
  ...bookReviewSkillStructuralSEOSupports,
  ...bookReviewLevelStructuralSEOSupports,
];
