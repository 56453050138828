import { capitalize } from 'lodash';
import { StructuralSEOItem, StructuralSEOSupportId } from '../seo-filter.types';
import { formatTextSEOStructural, seoFilterSlugifyUrl } from './_CommonPageSEOStructure';

const getVocabularyLabel = (value: string) => {
  switch (value) {
    case 'thong_dung':
      return 'Thông dụng';
    case 'theo_chu_de':
      return 'Theo chủ đề';
    default:
      return capitalize(value);
  }
};

const vocabularyValueToVariable = (value) => {
  let mappedType = value;
  switch (value) {
    case 'COMMON': {
      mappedType = 'thong_dung';
      break;
    }
    case 'TOPIC': {
      mappedType = 'theo_chu_de';
      break;
    }
    default:
  }
  return { vocabularyType: mappedType };
};

const formatVocabularyTitleAndDescription = (value) =>
  formatTextSEOStructural({
    prefixText: '',
    formatFn: getVocabularyLabel,
    value: value?.vocabularyType,
  });

export const vocabularyStructuralSEOSupports: StructuralSEOItem[] = [
  {
    id: StructuralSEOSupportId.VOCABULARY_TYPE,
    tokens: ['{{phan_loai}}'],
    urlTokens: [
      {
        pattern: /-vocabulary-(.*)/,
        variables: ['vocabularyType'],
      },
    ],
    getUrl: (value) => `-vocabulary-${seoFilterSlugifyUrl(value.vocabularyType)}`,
    getTitle: formatVocabularyTitleAndDescription,
    getDescription: formatVocabularyTitleAndDescription,
    toVariables: vocabularyValueToVariable,
    toTokens: (value) => ({ phan_loai: value }),
  },
];
